body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background-color: #ffffff;
  --text-color: #000000;
}

body.dark-mode {
  --background-color: #2E2E2E;
  --text-color: #FFFFFF;
}

@media print {
  body {
    background-color: #ffffff !important; /* Light background */
    color: #000000 !important; /* Dark text */
    visibility: hidden;
  }
  
  /* Ensure all text fields have light mode borders */
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #000000 !important; /* Dark border */
  }

  /* Additional styling for other components */
  .MuiTypography-root {
    color: #000000 !important;
  }
  
  .MuiButton-root {
    color: #000000 !important;
    border-color: #000000 !important;
  }
  
  #printableArea, #printableArea * {
    visibility: visible;
  }
  
  #printableArea {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  
  .no-print {
    display: none;
  }
}
